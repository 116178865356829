import {AppProvider,  AppContext} from './context'
import {aaSendClickEvent, aaInitApp, getHits as getSearchResults} from './searchEngine'
import {MedicalWebPageSEO, WebPageSEO, FAQPageSEO,CategoryPageSEO, HomePageSEO, PPCMetadata} from './seo'
import {useIntersection} from './hooks'
import {SendPageView, SendEvent, SendTimeOnPage, saveMarketingCookies, SendUniqueClickout, ViewPromotionEvent, SelectPromotionEvent} from './analytics'
import {getSession} from './session'
import {handlePageNotFound} from './pageNotFoundHandling'
import {
  getPosts,
  getPostDetails,
  getAuthors,
  getAuthorDetails,
  grpahCMSImageLoader,
  getPostsByCategory,
  getPostsBySubcategory,
  GET_POSTS_BY_AUTHOR,
  GET_POSTS_BY_CATEGORY,
  cdnLoader,
  getDeeplinkByName,
  getMoreFromTheSameCategory,
  getAllCompliances,
  getComplianceDetails,
  getCategoryPageBySlug,
  getPostsForPath,
  getPostsByCategoryId,
  getPostsByCategorySubcategorySlug,
  getPageDetails,
  getMenuByName,
  getPromotionBySlug,
  request as contentAPIRequest,
} from './contentAPI'

import { getAuthorPageUrl, slugify, canonicalURLs } from './url-helpers/urlHelpers'



export {
  getAuthorPageUrl,
  getPosts,
  getPostDetails,
  getPostsByCategory,
  getPostsBySubcategory,
  getMoreFromTheSameCategory,
  getAuthors,
  getAuthorDetails,
  grpahCMSImageLoader,
  aaSendClickEvent,
  SendUniqueClickout,
  aaInitApp,
  MedicalWebPageSEO,
  WebPageSEO,
  FAQPageSEO,
  CategoryPageSEO,
  PPCMetadata,
  HomePageSEO,
  getSearchResults,
  GET_POSTS_BY_AUTHOR,
  GET_POSTS_BY_CATEGORY,
  SendEvent,
  ViewPromotionEvent,
  SelectPromotionEvent,
  SendPageView,
  cdnLoader,
  getDeeplinkByName,
  SendTimeOnPage,
  getAllCompliances,
 getComplianceDetails,
 saveMarketingCookies,
  getCategoryPageBySlug,
  getPostsForPath,
  getPostsByCategoryId,
  getPostsByCategorySubcategorySlug, 
  getPageDetails,
  getPromotionBySlug,
  AppProvider,  
  AppContext,
  slugify,
  canonicalURLs,
  getMenuByName,
  getSession,
  useIntersection,
  handlePageNotFound,
  contentAPIRequest
};
