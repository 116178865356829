import { gql } from "graphql-request";
export const PROMOTION_FIELDS = gql`
    fragment PromotionFields on Promotion {
        id
        score
        slug
        couponText
        ribbon
        features_md
        deepLink {
            ...DeepLinkFields
        }
        advertiser {
            ...BrandFields
        }
        callToAction
        landing_page_preview_image {
            ...AssetFields
            url(
                transformation: {
                    image: { resize: { width: 500, fit: clip } }
                    document: { output: { format: webp } }
                    validateOptions: true
                }
            )
        }
    }
`;