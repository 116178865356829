const SITE_URL = "https://www.stdwatch.com"
const AUTHORS_INDEX_URI = "medical-team"
// TODO: Move constants to a central configuration file

export const getAuthorPageUrl = (slug) => {
    return `${SITE_URL}/${AUTHORS_INDEX_URI}/${slug}`
}

export const slugify = (text) =>  {
    return text
    .toLowerCase() // Convert string to lowercase
    .replace(/[^\w\s-]/g, '') // Remove non-word characters except spaces and dashes
    .replace(/^\d+/, '') // Remove leading numbers
    .replace(/\s+/g, '-') // Replace spaces with dashes
    .replace(/_+/g, '-') // Replace underscores with dashes
    .replace(/-+/g, '-') // Replace multiple dashes with single dash
    .replace(/^-+|-+$/g, ''); // Trim dashes from the beginning and end of the string
    
};

// extract slug from canonicalURLs (SEO on GraphCMS) for "review" pages and "std-tests" pages to use siteUrl variable to create dynamic canonicalURLs
export const canonicalURLs = (post, siteUrl) => post.node.seo?.canonicalUrl ? `${siteUrl}${post.node.seo?.canonicalUrl.split('www.stdwatch.com/').pop()}` : `${siteUrl}blog/${post.node.slug}`; 

