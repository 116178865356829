import { gql } from '@apollo/client';

export const PRODUCT_REVIEW_FIELDS = gql`
  fragment ProductReviewFields on ProductReview {
    id
    name
    description
    pros
    cons
    productScore: score
    brand {
      name
      domain
    }
    productLink: deepLink {
      id
      name
      affiliate_link
    }
    image{
        ...AssetFields
    }
  }
`;