'use client'

export const getSession = () => {
    let _session;
    try {
        _session = JSON.parse(localStorage.getItem('session') || '{}')
    }
    catch (e) {
        _session = {}
    }
    return _session
}