import { gql } from "graphql-request";

export const AUTHOR_FIELDS = gql`
    fragment AuthorFields on Author {
        name
        id
        slug
        linkedin
        title
        picture {
            ...AssetFields
        }
    }
`;