import { gql } from "graphql-request";
export const BRAND_FIELDS = gql`
    fragment BrandFields on Advertiser {
        id
        name
        isDisabled
        logo {
            ...AssetFields
            url(
                transformation: {
                    image: { resize: { width: 500, fit: clip } }
                    document: { output: { format: webp } }
                    validateOptions: true
                }
            )
        }
        features_md
        landing_page_preview_image {
            ...AssetFields
            url(
                transformation: {
                    image: { resize: { width: 500, fit: clip } }
                    document: { output: { format: webp } }
                    validateOptions: true
                }
            )
        }
        deepLink {
            id
            name
            affiliate_link
        }
        domain
        defaultCouponText
        review: post { slug }
    }
`;