import {MedicalWebPageSEO} from "./MedicalWebPage";
import {FAQPageSEO} from "./FAQPage";
import {CategoryPageSEO} from "./CategoryPageSEO";
import {HomePageSEO} from "./HomePageSEO";
import * as WebPageSEO from "./WebPage";
import {default as PPCMetadata} from "./PPCMetadata";

export {
    MedicalWebPageSEO,
    WebPageSEO,
    FAQPageSEO,
    CategoryPageSEO,
    HomePageSEO,
    PPCMetadata
}