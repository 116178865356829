'use client';

import { getSession } from "./session";
const CLICKOUT_UNIQUE_EVENT = "clickout-unique";
// https://medium.com/@mikegajdos81/how-to-add-googleanalytics-4-to-nextjs-app-in-4-simple-steps-3c6f9de2f866

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const SendPageView = (url) => {
  window.gtag("config", process.env.NEXT_PUBLIC_GA_ID, {
    page_path: url,
  });
};


// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const SendUniqueClickout = ( {Advertiser, PagePath, Element, Text}) => {
  // Will send a clickout event every time, uniqueness is configured via google analytics. not here. name of event is not changed due to upstream integrations
  console.debug("SendUniqueClickout", {Advertiser, PagePath, Element, Text})
  window.gtag("event", CLICKOUT_UNIQUE_EVENT, {
    Advertiser,
    PagePath,
    Element,
    Text: typeof Text !== "string" ? "" : Text,
  });
};

export const SendEvent = ( {eventName, component, value , section, href, text}) => {
  console.debug("SendEvent", {eventName, component, value , section, href, text})
  window.gtag("event", eventName, {
    'Component': component,
    'Value': value,
    'Section': section,
    "href": href,
    "text": text,
  });
};

export const ViewPromotionEvent = (creative_name, creative_slot, items) => {
  window.gtag("event", "view_promotion", {
      "creative_name": creative_name,
      "creative_slot": creative_slot,
      "items": items
  })
}

export const SelectPromotionEvent = (creative_name, creative_slot, items) => {
  window.gtag("event", "select_promotion", {
      "creative_name": creative_name,
      "creative_slot": creative_slot,
      "items": items
  })
}


export const SendTimeOnPage = ({ name, value, category }) => {
  window.gtag("event", "timing_complete", {
    event_category: category,
    name: name,
    value: value,
  });
};


const setCookie = (cookie, days=7) => {
  const date = new Date();
  date.setTime(date.getTime() + (days*24*60*60*1000)); 
  const expires = "; expires=" + date.toUTCString();
  document.cookie = "__session=" + cookie + expires + ";path=/";
}

function setSession(sessionObj, days=7){
  // read session from localStorage and update it with new session object
  // and save it in localStorage
  const rawSession = JSON.stringify(sessionObj)
  localStorage.setItem('session', rawSession);
  setCookie(rawSession);
  return;
}
function uuidv4() {
  return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
}

export const createSession = ()=>{
  let _session;
  try {
      _session = JSON.parse(localStorage.getItem('session')) || {userId: uuidv4()}
  }
  catch (e){
    // throw new Error("session could not be parsed")
    _session = {userId: uuidv4()}         
  }
  const cookie = "{\"" + window.location.search.substring(1).replace(/=/g, "\":\"").replace(/&/g, "\",\"") + "\"}"
  let jsonCookie;
  try{
      jsonCookie = JSON.parse(cookie) || {}
  }
  catch(e){
      jsonCookie = _session
  }
  jsonCookie = Object.assign(_session, jsonCookie)
  
  return _session
}

export const saveMarketingCookies = ()=> {
  try {
      const session = createSession();
      session.href = session.href || window.location.href;
      session.referrer = window.location.href.split('?')[0];
      // Send initial PPC tag for first time user
      // if (!session.tagged && session.gclid){
      //     sendCustomTag('ppc', 'visit', session.gclid);
      //     session.tagged = true;
      // }
      setSession(session);
  }
  catch(e){
     console.error("session could not be set", e)
  }
}