import { gql } from "graphql-request";
export const POST_FIELDS = gql`
    fragment PostFields on Post {
        title
        subCategory
        category# {
        #   title
        #   slug
        # }
        excerpt
        coverImage {
          ...AssetFields
          url(
              transformation: {
                image: { resize: { width: 300, fit: clip } }
                document: { output: { format: webp } }
                validateOptions: true
              }
            )
        }
        author {
          ...AuthorFields
        }
        createdAt
        updatedAt
        slug
        excerpt
        # markdownContent
        seo {
          ...MetadataFields
        }
    }
`;