import { gql } from "graphql-request";

export const SITE_CONFIG_FIELDS = gql`
    fragment SiteConfigFields on SiteConfig {
        name
        id
        domain
        logo {
            ...AssetFields
        }
        logoMobile {
            ...AssetFields
        }
        colorPalette
        gaMeasurementId
        gtmId
        favicon {
            ...AssetFields
            url(
                transformation: {
                    validateOptions: true
                }
            )
        }
    }
`;