import aa from 'search-insights';
import { getSession } from "./session"
let initialized = false;

const getUserToken = () => getSession()['gclid'] || getSession()['msclkid'] || getSession()["userId"]
const getIndexName = () => process.env['NEXT_PUBLIC_ALGOLIA_INDEX_NAME'] || ''
interface AlogliaIndexSearchResponse {
    exhaustive: any
    exhaustiveNbHits: boolean
    exhaustiveTypo: boolean
    hits: Array<any>
    hitsPerPage: number
    nbHits: number
    nbPages: number
    page: number
    params: string
    processingTimeMS: number
    processingTimingsMS: any
    query: string
    queryID: string
    renderingContent: any
    serverTimeMS: number
    _automaticInsights: true
}
export const aaInitApp = (userToken: string) => {
    aa('init', {
        appId: process.env['NEXT_PUBLIC_ALGOLIA_APP_ID'],
        apiKey: process.env['NEXT_PUBLIC_ALGOLIA_API_KEY'],
    });
    initialized = true;
    return initialized;
}

export const aaSendClickEvent = (item) => {
    aa('clickedObjectIDsAfterSearch', {
        userToken: getUserToken(),
        index: getIndexName(),
        eventName: 'Product Clicked',
        queryID: item.__queryID,
        objectIDs: [item.objectID],
        positions: [item.__position]
    })
}

export const getHits = async ({ query, hitsNumber = 10 }) => {
    const headers = {
        'X-Algolia-API-Key': process.env['NEXT_PUBLIC_ALGOLIA_API_KEY'] || '',
        'X-Algolia-Application-Id': process.env['NEXT_PUBLIC_ALGOLIA_APP_ID'] || ''
    }
    const index = getIndexName()
    return fetch(`https://${process.env.NEXT_PUBLIC_ALGOLIA_APP_ID}-dsn.algolia.net/1/indexes/${index}?query=${query}&hitsPerPage=${hitsNumber}`, { headers })
        .then(response => response.json())
        .then((data: AlogliaIndexSearchResponse) => {
            return data
        })
}