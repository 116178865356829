import * as helpers from '../url-helpers/urlHelpers'
import moment from 'moment';
import { NewsArticleJsonLd } from 'next-seo';
import { NextSeo } from 'next-seo';

const SEO_TYPE = "MedicalWebPage" // TODO: add to seo schema
// TODO: Move constants to a central configuration file
const SITE_NAME = "STDWatch.com"
const SITE_URL = "https://www.stdwatch.com/"
const SITE_LOGO = "https://www.stdwatch.com/assets/design/menu/logo.png"

const UNDEFINED_SEO_VALUE = undefined;

export const getAuthorSeoDetails =({name, slug}) =>{
    return {name: name ? name : UNDEFINED_SEO_VALUE, url: helpers.getAuthorPageUrl(slug)}
}
const generateOpenGraphSchema = ({slug, title, coverImage, seo, createdAt, author, section, tags}) => {
  return {
      url: seo?.canonicalUrl ? seo.canonicalUrl : `${SITE_URL}blog/${slug}`,
      siteName: SITE_NAME,
      title: title ? title : UNDEFINED_SEO_VALUE,  
      article: {
        publishedTime: createdAt ? moment(createdAt).toISOString() : UNDEFINED_SEO_VALUE,
        modifiedTime: seo && seo.publishedAt ? moment(seo.publishedAt).toISOString() : UNDEFINED_SEO_VALUE,
       authors:[ 
          author ? author.name : UNDEFINED_SEO_VALUE,
        ],
        // TODO: Add section to post model
        section,
        // TODO: Add tags to post model
        tags,
      },
      images: [
        {
          url: coverImage ? coverImage.url.replace('media.graphassets.com/', 'media.graphassets.com/compress/output=format:webp/resize=width:400/') : UNDEFINED_SEO_VALUE,
          // TODO: Fetch the image dimensions from graphcms
          width: 400,
          height: 250,
          alt: slug ? slug : UNDEFINED_SEO_VALUE,//TODO: Replace this with a better alt text
        },
      ],
      description: seo && seo.description,
  }
}

export const generateSeoSchema = (post) => {
    return {
      type: SEO_TYPE,
      title: post.title ? post.title : UNDEFINED_SEO_VALUE,
      // TODO: support about field - https://schema.org/MedicalEntity, specifically MedicalCondition, MedicalRiskFactor, MedicalTest...
      // about: post.seo.about ? post.seo.about : UNDEFINED_SEO_VALUE,
      breadcrumb: {
        "@type": "BreadcrumbList",
        "itemListElement": [
            {
                "@type": "ListItem",
                "position": 1,
                "item": {
                    "@id": "https://www.stdwatch.com/",
                    "name": "Home"
                }
            },
            {
                "@type": "ListItem",
                "position": 2,
                "item": {
                    "@id": "https://www.stdwatch.com/blog",
                    "name": "Blog"
                }   
            },
            {
                "@type": "ListItem",
                "position": 3,
                "item": {
                    "@id": "https://www.stdwatch.com/blog/" + post.slug,
                    "name": post.title
                }
            }
        ]
    },
    datePublished: post.createdAt ? moment(post.createdAt).format('MMM DD, YYYY') : UNDEFINED_SEO_VALUE,
    dateModified: post.seo && post.seo.publishedAt ? moment(post.seo.publishedAt).format('MMM DD, YYYY') : '',
    section: post.seo && post.seo.section ? post.seo.section : UNDEFINED_SEO_VALUE,
    author: post.author && getAuthorSeoDetails(post.author),
    description: post.seo && post.seo.description ? post.seo.description : UNDEFINED_SEO_VALUE,
    keywords: post.seo && post.seo.keywords ? post.seo.keywords : UNDEFINED_SEO_VALUE,
    lastReviewed: post.seo && post.seo.lastReviewed ? post.seo.lastReviewed : UNDEFINED_SEO_VALUE,
    image: post.coverImage ? post.coverImage.url : UNDEFINED_SEO_VALUE,
    publisherName: SITE_NAME,
    publisherLogo: SITE_LOGO,
  }
}



export function MedicalWebPageSEO({data}){
  const seo = {
    openGraph: generateOpenGraphSchema(data),
    ...generateSeoSchema(data),
    canonical: data.seo?.canonicalUrl ? data.seo.canonicalUrl : `${SITE_URL}blog/${data.slug}`,
  }
  return (
    <>
    <NextSeo {...seo} />
    <NewsArticleJsonLd {...generateSeoSchema(data)} />
    </>
  )
}