import * as helpers from '../url-helpers/urlHelpers'

import moment from 'moment';
import { validate } from 'graphql';

const CONTEXT = "http://schema.org"
// TODO: add to seo schema
// TODO: Move constants to a central configuration file
const SITE_NAME = "STDWatch.com"
const SITE_URL = "https://www.stdwatch.com/"
const SITE_LOGO = "https://www.stdwatch.com/assets/design/menu/logo.png"

// TODO: Extract to another file
const getAuthorSeoDetails =({name, slug}) =>{
    return {name: name ? name : '', url: helpers.getAuthorPageUrl(slug)}
}


const validateOpenGraphSchema = ({openGraphSchema}) => {
    if (!openGraphSchema.title) {
        throw new Error('slug is required for page ')
    }
    if (!openGraphSchema.description) {
        throw new Error('description is required for page ')
    }
    if (!openGraphSchema.url) {
        throw new Error('url is required for page ')
    }
    return openGraphSchema
}
const  validateSchema = (schema) => {
    if (!schema.canonical) {
        throw new Error('canonical is required for page ')
    }
    if (!schema.openGraph) {
        throw new Error('openGraphSchema is required for page ')
    }
    validateOpenGraphSchema(schema.openGraph)
    return schema
}
const generateOpenGraphSchema = ({title, slug, path, description}) => {
    path = path ? path : slug
    return {
        url: `${SITE_URL}${path}`,
        title,
        // images: [
        //   {
        //     url: coverImage ? coverImage.url : '',
        //     width: 1200,
        //     height: 600,
        //     alt: slug ? slug : '',//TODO: Replace this with a better alt text
        //   },
        // ],
        description: description,
        site_name: SITE_NAME,
      }
}
export const generateSEOSchema = ({title, slug, path, description, createdAt, dateModified, seo, coverImage, author}) => {
    
    path = path ? path : slug
    return validateSchema(
        {
            type: "WebPage",
            // TODO: Implement breadcrumbs for regular pages based on url structure
            // breadcrumb: {
            //     "@type": "BreadcrumbList",
            //     "itemListElement": [
            //         {
            //             "@type": "ListItem",
            //             "position": 1,
            //             "item": {
            //                 "@id": "https://www.stdwatch.com/",
            //                 "name": "Home"
            //             }
            //         },
            //     ]
            // },
            canonical:`${SITE_URL}${path}`,
            title,
            url: `${SITE_URL}blog/${slug}`,
            datePublished: createdAt ? moment(createdAt).format('MMM DD, YYYY') : '',
            dateModified: seo.dateModified ? moment(seo.dateModified).format('MMM DD, YYYY') : '',
            section: seo.section ?? '',
            authorName: [
                author ? getAuthorSeoDetails(author) : undefined
            ],
            description: description ? description : 'A blog about STDs',
            keywords: seo.keywords ? seo.keywords : '',
            lastReviewed: seo.lastReviewed ? seo.lastReviewed : '',
            image: [coverImage.url ? coverImage.url : ''],
            publisherName: SITE_NAME,
            publisherLogo: SITE_LOGO,
            openGraph: generateOpenGraphSchema({title, slug, path, description})
        }
    )
}
  