import { gql } from "graphql-request";

export const METADATA_FIELDS = gql`
    fragment MetadataFields on Seo {
        id,
        title,
        description,
        canonicalUrl,
        keywords,
        lastReviewed,
        reviewedBy {
            name
        },
        dateModified,
        updatedAt,
        publishedAt
    }
`;

