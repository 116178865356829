import { gql } from "graphql-request";
export const NAVIGATION_MENU_FIELDS = gql`
    fragment NavigationMenuFields on NavigationMenu {
        name
        content(first: 50) {
            ... on NavMenuLink {
                id
                NavMenuLink_title_alias: title
                NavMenuLink_url_alias: url
                NavMenuLink_isColored_alias: isColored
            }
            ... on NavMenuDropdown {
                id
                NavMenuDropdown_title_alias: title
                NavMenuDropdown_links_alias: links(first: 50) {
                    id
                    title
                    url
                    isColored
                }
            }
        }
    }
`;