
import 'focus-visible'
import Head from 'next/head'
import React, { useEffect } from 'react';
import Script from "next/script";
import { useRouter } from "next/router";
import {SendPageView,  saveMarketingCookies, AppProvider} from '@monorepo-nx/std-watch/services';
import '../styles/main.css';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import { SpeedInsights } from '@vercel/speed-insights/react';

const endpoint = process.env["NEXT_PUBLIC_GRAPHQL_API"]

const cache = new InMemoryCache();

const client = new ApolloClient({
  uri: 'https://api-us-east-1.hygraph.com/v2/ckyw7qsmi0zrt01z01kage3cx/test',
  cache: cache,
});




function CustomApp({ Component, pageProps }) {

  const router = useRouter();
  useEffect(() => {
    saveMarketingCookies();
    const handleRouteChange = (url) => {
      SendPageView(url);
    };
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);
  // console.info("Page Props", pageProps)



  return (


    <ApolloProvider client={client}>
       <AppProvider subMenu={pageProps?.subMenu} footer={pageProps?.footer} categoryPage={pageProps?.categoryPage} endpoint={endpoint}>
      <>
       <Head>
          {/* Every page is expected to contains a title property */}
          <title>{Component.title ? Component.title : pageProps.title}| STDWatch.com</title>
          <meta
            name="description"
            content={pageProps.description || "A blog about STDs and STIs awareness and prevention."}
          />
          <meta name="referrer" content="origin"/>
      </Head>

        {/* Global Site Tag (gtag.js) - Google Analytics */}
        <Script
          strategy="afterInteractive"
          src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GA_ID}`}
        />
        <Script
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${process.env.NEXT_PUBLIC_GA_ID}', {
              page_path: window.location.pathname,
            });
          `,
          }}
        />


    {/* Google Tag Manager */}
    <Script id={"gtag-manager"}
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GTM_ID}');
            `,
          }}
        />
   
          {/* <Layout> */}
            <Component {...pageProps} />
            <SpeedInsights debug={false}/>
          {/* </Layout> */}
        
      </>
      </AppProvider>
    </ApolloProvider>
  );
}
export default CustomApp;
