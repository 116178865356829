import { gql } from "graphql-request";
export const POST_WITH_RTF_FIELDS = gql`
    fragment PostWithRTFFields on Post {
        ...PostFields
        content {
          json
          references {
             ... on Post {
              id
              slug
              category
            }
            ... on Page {
              id
              url 
            }
             ... on ProductReview {
               ...ProductReviewFields
             }
            ... on Asset {
              ...AssetFields
              url(
                transformation: {
                  image: { resize: { width: 400, fit: clip } }
                  document: { output: { format: webp } }
                  validateOptions: true
                }
              )
              mimeType
            }
            ... on DeepLink {
              ...DeepLinkFields
            }
            ... on Faq {
              id
              question
              answer
            }
            ... on Promotion {
              ...PromotionFields
            }
          }
        }
    }
`;