import { NextSeo } from "next-seo"

interface Asset {
    id: string;
    url: string;
    altText: string;
}
interface PageMetadata {
    title: string;
    description: string;
    image?: Asset
    url: string;
    sitename: string
}

const PPcMetadata = ({ title, description, image, url, sitename }: PageMetadata) => (

    <NextSeo
        title={title}
        description={description}
        noindex={true}
        nofollow={true}
        openGraph={{
            url: url,
            title: title,
            description: description,
            images: [
                {
                    url: image ? image.url.replace('media.graphassets.com/', 'media.graphassets.com/compress/output=format:webp/resize=width:400/') : '',
                    width: 400,
                    height: 250,
                    alt: sitename
                },
            ],
            site_name: sitename,

        }
        }
    />
)
export default PPcMetadata