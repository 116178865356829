import moment from "moment";
import { NextSeo, WebPageJsonLd, BreadcrumbJsonLd } from "next-seo";


const SITE_NAME = "STDWatch.com"
const SITE_URL = "https://www.stdwatch.com/"
const SITE_LOGO = "https://www.stdwatch.com/assets/design/menu/logo.png"

export function HomePageSEO({ data }) {

    return (
        <>
            <NextSeo
                title={`${data.seo[0].title}`}
                canonical={`${data.seo[0].canonicalUrl}`}
                description={`${data.seo[0].description}`}
                openGraph={{
                    url: SITE_URL,
                    title: `${data.seo[0].title}`,
                    description: `${data.seo[0].description}`,
                    images: [
                        {
                            url: data.seo[0].image.url.replace('media.graphassets.com/', 'media.graphassets.com/compress/output=format:webp/resize=width:400/'),
                            width: 400,
                            height: 250,
                            alt: "home-page"
                        },
                    ],
                    site_name: SITE_NAME,
                }}
            />
            <WebPageJsonLd
                mainEntityOfPage={
                    {
                        "@type": "WebPage",
                        "id": "https://stdwatch.com/"
                    }
                }

                headline={`${data.seo[0].title}`}
                image={ data.seo[0].image.url.replace('media.graphassets.com/', 'media.graphassets.com/compress/output=format:webp/resize=width:400/')}
                datePublished={`${moment(data.createdAt).format('MMM DD, YYYY')}`}
                dateModified={`${moment(data.updatedAt).format('MMM DD, YYYY')}`}
                description={`${data.seo[0].description}`}
                publisher={{
                    "@type": "Organization",
                    "name": SITE_NAME,
                    "url": SITE_URL,
                    "logo": {
                        "@type": "ImageObject",
                        "url": SITE_LOGO,
                    }
                }}
            />
            <BreadcrumbJsonLd
                itemListElements={[
                    {
                        position: 1,
                        name: "Home",
                        item: "https://www.stdwatch.com/",
                    }
                ]}
            />
        </>
    )
}