import { gql } from "graphql-request";

export const ASSET_FIELDS = gql`
    fragment AssetFields on Asset {
        width
        height
        url(
          transformation: {
              image: { resize: { width: $width, fit: clip } }
              document: { output: { format: webp } }
              validateOptions: true
          }
        )
    }`