import { NextSeo, CollectionPageJsonLd, BreadcrumbJsonLd, WebPageJsonLd } from 'next-seo';


const UNDEFINED_SEO_VALUE = undefined;

export function CategoryPageSEO({ title, description, category, currentPage, coverImage, pagesCount, subCategory, dateModified }) {
  if(category === undefined){
    throw new Error("Category is undefined")
  }
  const fullTitle = `${title ? title : category} page ${currentPage}`
  return (
    <>
      <NextSeo
        title={fullTitle}
        description={description}
        canonical={subCategory ? `https://www.stdwatch.com/${category}/${subCategory}/page/${currentPage}` : `https://www.stdwatch.com/${category}/page/${currentPage}`}
        openGraph={{
          url: subCategory ? `https://www.stdwatch.com/${category}/${subCategory}/page/${currentPage}` : `https://www.stdwatch.com/${category}/page/${currentPage}`,
          title: fullTitle,
          description,
          images: [
            {
              url: coverImage ? coverImage.url.replace('media.graphassets.com/', 'media.graphassets.com/compress/output=format:webp/resize=width:400/') : UNDEFINED_SEO_VALUE,
              width: 400,
              height: 250,
              alt: null
            },
          ],
          site_name: 'STDWatch.com',
        }}
      />
       <WebPageJsonLd
        id={subCategory ? `https://www.stdwatch.com/${category}/${subCategory}/page/${currentPage}` : `https://www.stdwatch.com/${category}/page/${currentPage}`}
        headline={`Health Diagnostics & Wellness Blog - Page ${currentPage} out of ${pagesCount}`}
        image={coverImage?.url.replace('media.graphassets.com/', 'media.graphassets.com/compress/output=format:webp/resize=width:400/')}
        dateModified = {`${dateModified}`}
        description="Take control of your health with our trusted health diagnostics and wellness blog, offering evidence-based recommendations and insights"
      />
     
       <BreadcrumbJsonLd
        itemListElements={[
          {
            position: 1,
            name: "Home",
            item: "https://www.stdwatch.com/",
          },
          {
            position: 2,
            name: `${category}`,
            item: `https://www.stdwatch.com/${category}`,
          },
          {
            position: 3,
            name: `${subCategory}`,
            item:`https://www.stdwatch.com/${category}/${subCategory}`,
          }
        ]}
      />
    </>
  )
}

